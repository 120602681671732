<template>
  <v-card>
    <v-toolbar dense flat class="teal" dark>
      <v-toolbar-title>Editar Endereço</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="address.district"
              :counter="100"
              label="Nome do Endereço"
              placeholder="digite um endereço"
              required
              outline
            ></v-text-field>
            <v-select
              v-model="address.city"
              :items="citys"
              item-text="name"
              item-value="id"
              label="Cidade"
              return-value
              single-line
            ></v-select>
            <v-select
              v-model="address.area"
              :items="areas"
              item-text="name"
              item-value="id"
              label="Área"
              return-value
              single-line
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn color="success" outlined @click="save()"> Salvar </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" outlined @click="closeDialog()"> Cancelar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "EditAddress",
  props: ["status"],

  data() {
    return {
      address: Object.assign({}, this.status),
      citys: [],
      areas: []
    };
  },
  created() {
    console.log(this.address);
    this.loadCitys();
    this.loadAreas();
  },
  methods: {
    async closeDialog() {
      this.$emit("close-dialog");
    },
    async save() {
      try {
        const response = await this.$http.put("/address", this.address);
        if (response) this.$toast.success("Critério editada com sucesso!");
        this.$emit("update");
        this.$emit("close-dialog");
      } catch (error) {
        this.$toast.error("Tente novamente mais tarde!");
        this.$emit("error");
      }
    },
    async loadCitys() {
      const response = await this.$http.get("/city");

      this.citys = response.data;
    },
    async loadAreas() {
      const response = await this.$http.get("/area");
      this.areas = response.data;
    }
  }
};
</script>

<style></style>
