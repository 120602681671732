<template>
  <v-container>
    <v-card flat>
      <v-card-text>
        <v-data-table
          v-if="address"
          :headers="headers"
          :items="address"
          class="elevation-2"
        >
          <template v-slot:top>
            <v-toolbar dense flat class="teal" dark>
              <v-toolbar-title>Áreas/Bairros</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{}">
                  <v-btn
                    class="mt-2 mb-2"
                    icon
                    x-large
                    to="/endereco/adicionar"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                Adicionar novo Área
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click="edit(item)" class="mr-2 colorLink"
              >mdi-square-edit-outline</v-icon
            >
            <v-icon @click="remove(item)" class="mr-2" color="error"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-card flat>
      <v-card-text>
        <v-data-table
          v-if="citys"
          :headers="headersCitys"
          :items="citys"
          class="elevation-2"
        >
          <template v-slot:top>
            <v-toolbar dense flat class="teal" dark>
              <v-toolbar-title>Cidades</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{}">
                  <v-btn class="mt-2 mb-2" icon x-large to="/address/adicionar">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                Adicionar nova Cidade
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click="edit(item)" class="mr-2 colorLink"
              >mdi-square-edit-outline</v-icon
            >
            <v-icon @click="remove(item)" class="mr-2" color="error"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" max-width="500px" v-if="editDialog">
      <editArea
        :status="disctrict"
        v-on:update="loadAddress()"
        v-on:close-dialog="editDialog = !editDialog"
      />
    </v-dialog>
    <v-dialog v-model="removeDialog" max-width="500px" v-if="removeDialog">
      <removeArea
        :status="disctrict"
        v-on:update="loadAddress()"
        v-on:close-dialog="removeDialog = !removeDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import editArea from "./edit";
import removeArea from "./remove";
export default {
  components: {
    editArea,
    removeArea
  },
  data() {
    return {
      address: [],
      disctrict: {},
      citys: [],
      editDialog: false,
      removeDialog: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Área", value: "area_name" },
        { text: "Cidade", value: "city_name" },
        { text: "Bairro", value: "district" },

        { text: "Ações", value: "actions" }
      ],
      headersCitys: [
        { text: "ID", value: "id" },
        { text: "Cidade", value: "name" },

        { text: "Ações", value: "actions" }
      ]
    };
  },
  created() {
    this.loadAddress();
    this.loadCitys();
  },
  methods: {
    async loadAddress() {
      const response = await this.$http.get("/address");
      this.address = response.data;
    },
    async loadCitys() {
      const response = await this.$http.get("/city");

      this.citys = response.data;
    },
    async edit(address) {
      this.editDialog = true;
      this.disctrict = address;
    },
    async remove(address) {
      this.removeDialog = true;
      this.disctrict = address;
    }
  }
};
</script>

<style></style>
